<template>
  <gf-base>
    <gf-card :tab="tabs" :active-tab.sync="activeTab" @tab-click="tabClick">
      <router-view :active.sync="activeTab"></router-view>
    </gf-card>
  </gf-base>
</template>

<script>
export default {
  data () {
    return {
      activeTab: 1,
      tabs: [
        { id: 1, label: 'Payment terms' },
        { id: 2, label: 'Payment Methods' }
      ]
    }
  },
  methods: {
    tabClick (id) {
      switch (id) {
        case 1:
          this.$router.push({ name: 'payment-terms-list' })
          break
        case 2:
          this.$router.push({ name: 'payment-methods-list' })
          break
        default:
          break
      }
    }
  }
}
</script>
